import './locales/i18n';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import { gaMeasurementId } from './config';
import GlobalStyle from './globalStyle';
import Routing from './router';
import Theme from './Theme';

ReactGA.initialize(gaMeasurementId);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <div>
    <Theme>
      <GlobalStyle />
      <Routing />
    </Theme>
  </div>,
);
