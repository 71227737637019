import React from 'react';
import styled from 'styled-components';

import termsAndConditions from '../../../assets/termsAndConditions.png';
import CloseIcon from '../../../icons/Close';
import { deviceBreakpoints } from '../../../Theme';

interface ModalProps {
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ onClose }) => {
  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <TermsAndConditions src={termsAndConditions} />
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  backdrop-filter: blur(3px);
  position: fixed;
  z-index: 100;
  inset: 0;
  padding: 20px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  z-index: 10;
  width: inherit;
  height: 500px;
  width: 600px;
  position: relative;

  @media only screen and ${deviceBreakpoints.mobile} {
    height: 100%;
    width: 100%;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
`;

const TermsAndConditions = styled.img`
  width: inherit;
`;

export default Modal;
