import Button from 'components/atoms/Button/Button';
import Text from 'components/atoms/Text/Text';
import useRenderParagraphs from 'hooks/useRenderParagraphs';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { TranslationType } from 'utils/utils';

import DarwinMainVideo from '../../../assets/output.mp4';

const GifOverlay = () => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();

  const description = useRenderParagraphs(
    t('mainDescription', { returnObjects: true }) as TranslationType,
  );

  const titleType = isMobile ? 'title1' : 'display1';
  const descriptionType = isMobile ? 'caption1' : 'title3';
  const buttonType = isMobile ? 'label2' : 'body1normal';
  const size = isMobile ? 'small' : 'large';

  const videoSrc = `${DarwinMainVideo}?v=${new Date().getTime()}`;

  return (
    <GifOverlayContainer>
      <LottieDiv>
        <Video src={videoSrc} loop autoPlay muted playsInline />
      </LottieDiv>
      <ContentWrapper>
        <MainTitleWrapper>
          <Text type={titleType} weight='bold' textColor='white'>
            {t('mainTitle')}
          </Text>
        </MainTitleWrapper>
        <MainDescriptionWrapper>
          <Text type={descriptionType} textColor='white'>
            {description}
          </Text>
        </MainDescriptionWrapper>
        <ButtonWrapper>
          <Button
            onClickAction={() => navigate('/contact-us')}
            buttonType='blue-white'
            text={t('contact')}
            textColor='white'
            textType={buttonType}
            size={size}
            weight='bold'
          />
        </ButtonWrapper>
      </ContentWrapper>
    </GifOverlayContainer>
  );
};

const GifOverlayContainer = styled.div`
  position: relative;
  width: 100%;
  height: 57.6vh; /* Default height for larger screens */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media only screen and ${deviceBreakpoints.mobile} {
    height: 40vh;
  }
`;

const LottieDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.5);
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
    max-width: 350px;
    top: 50%;
    left: 50%;
  }
`;

const MainTitleWrapper = styled.div`
  white-space: nowrap;
  margin-bottom: 16px;
  padding-right: 10px;
  padding-left: 10px;
`;

const MainDescriptionWrapper = styled.div`
  white-space: pre-wrap;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
`;

export default GifOverlay;
