import React from 'react';

const MenuIcon: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_153_4243)'>
        <path
          d='M26.6667 24.1667H3.33333C3.11232 24.1667 2.90036 24.0789 2.74408 23.9226C2.5878 23.7663 2.5 23.5543 2.5 23.3333C2.5 23.1123 2.5878 22.9004 2.74408 22.7441C2.90036 22.5878 3.11232 22.5 3.33333 22.5H26.6667C26.8877 22.5 27.0996 22.5878 27.2559 22.7441C27.4122 22.9004 27.5 23.1123 27.5 23.3333C27.5 23.5543 27.4122 23.7663 27.2559 23.9226C27.0996 24.0789 26.8877 24.1667 26.6667 24.1667Z'
          fill='#0B0D1E'
        />
        <path
          d='M26.6667 15.8334H3.33333C3.11232 15.8334 2.90036 15.7456 2.74408 15.5893C2.5878 15.4331 2.5 15.2211 2.5 15.0001C2.5 14.7791 2.5878 14.5671 2.74408 14.4108C2.90036 14.2545 3.11232 14.1667 3.33333 14.1667H26.6667C26.8877 14.1667 27.0996 14.2545 27.2559 14.4108C27.4122 14.5671 27.5 14.7791 27.5 15.0001C27.5 15.2211 27.4122 15.4331 27.2559 15.5893C27.0996 15.7456 26.8877 15.8334 26.6667 15.8334Z'
          fill='#0B0D1E'
        />
        <path
          d='M26.6667 7.49992H3.33333C3.11232 7.49992 2.90036 7.41212 2.74408 7.25584C2.5878 7.09956 2.5 6.8876 2.5 6.66659C2.5 6.44557 2.5878 6.23361 2.74408 6.07733C2.90036 5.92105 3.11232 5.83325 3.33333 5.83325H26.6667C26.8877 5.83325 27.0996 5.92105 27.2559 6.07733C27.4122 6.23361 27.5 6.44557 27.5 6.66659C27.5 6.8876 27.4122 7.09956 27.2559 7.25584C27.0996 7.41212 26.8877 7.49992 26.6667 7.49992Z'
          fill='#0B0D1E'
        />
      </g>
      <defs>
        <clipPath id='clip0_153_4243'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuIcon;
