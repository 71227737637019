import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import emart from '../../../assets/aboutUs/emart.png';
import government from '../../../assets/aboutUs/government.png';
import hyundai from '../../../assets/aboutUs/hyundai.png';
import police from '../../../assets/aboutUs/police.png';
import shinsaegae from '../../../assets/aboutUs/shinsaegae.png';
import spoany from '../../../assets/aboutUs/spoany.png';
import Text from '../../../components/atoms/Text/Text';
import { deviceBreakpoints } from '../../../Theme';

const CustomerSection = () => {
  const { t } = useTranslation(['aboutUs']);

  const { isMobile } = useWindowDimensions();

  const title = isMobile ? 'title2' : 'display2';

  const areas = [
    {
      icon: hyundai,
      position: 'top-left',
    },
    {
      icon: government,
      position: 'top-middle',
    },
    {
      icon: shinsaegae,
      position: 'top-right',
    },
  ];

  const bottomAreas = [
    {
      icon: emart,
      position: 'bottom-left',
    },
    {
      icon: spoany,
      position: 'bottom-middle',
    },
    {
      icon: police,
      position: 'bottom-right',
    },
  ];

  return (
    <Container>
      <Text type={title} weight='bold' padding='0 20px'>
        {t('customersTitle')}
      </Text>
      <BoxContent>
        <AreaWrapper>
          {areas.map((area, index) => (
            <Area key={index} position={area.position}>
              <Image src={area.icon} />
            </Area>
          ))}
        </AreaWrapper>
        <AreaWrapper>
          {bottomAreas.map((area, index) => (
            <Area key={index} position={area.position}>
              <Image src={area.icon} />
            </Area>
          ))}
        </AreaWrapper>
      </BoxContent>
    </Container>
  );
};

const Container = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 112px;
  padding-top: 112px;
  gap: 80px;
  text-align: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-bottom: 40px;
    padding-top: 40px;
    gap: 32px;
  }
`;

const BoxContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1440px;
  margin: auto;
  gap: 40px;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    flex-direction: column;
    gap: 40px;
  }
`;

const AreaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 60px;
  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    flex-direction: column;
    gap: 40px;
  }
`;

const Area = styled.div<{ position: string }>`
  flex: 1 1 calc(33.333% - 60px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 60px;
  gap: 16px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex: 1 1 auto;
    padding: 0;
  }
`;

const Image = styled.img`
  display: flex;
  width: 420px;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export default CustomerSection;
