import React from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

import { gaMeasurementId } from '../../config';
import CustomerSection from './sections/CustomerSection';
import ImageSection from './sections/ImageSection';
import StorySection from './sections/Story';
import Values from './sections/Values';

ReactGA.initialize(gaMeasurementId);

const AboutUsPage: React.FC = () => {
  ReactGA.send({ hitType: 'pageview', page: '/about-us', title: 'AboutUs' });

  return (
    <Wrapper>
      <ImageSection />
      <StorySection />
      <Values />
      <CustomerSection />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
`;

export default AboutUsPage;
