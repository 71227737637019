import Button from 'components/atoms/Button/Button';
import useRenderParagraphs from 'hooks/useRenderParagraphs';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { TranslationType } from 'utils/utils';

import realGPT from '../../../assets/realGPT.png';
import realIOT from '../../../assets/realIOT.png';
import realSID from '../../../assets/realSID.png';
import realSTT from '../../../assets/realSTT.png';
import realTTS from '../../../assets/realTTS.png';
import Text from '../../../components/atoms/Text/Text';

const ProductsSection: React.FC = () => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();

  const titleType = isMobile ? 'title2' : 'display2';
  const areaTitle = isMobile ? 'heading1' : 'title3';
  const areaSubtitle = isMobile ? 'headline1' : 'heading2';
  const areaText = isMobile ? 'label1reading' : 'heading1';
  const buttonType = isMobile ? 'label2' : 'body1normal';
  const size = isMobile ? 'small' : 'large';

  const products = [
    {
      key: 'real-gpt',
      title: t('Real-GPT'),
      subtitle: 'Generative Pre-trained Transformer',
      description: useRenderParagraphs(
        t('realGPTDescription', { returnObjects: true }) as TranslationType,
      ),
      extendedDescription: t('realGPTExtendedDescription'),
      imageSrc: realGPT,
      url: '/products/real-gpt',
    },
    {
      key: 'real-stt',
      title: t('Real-STT'),
      subtitle: 'Speech-To-Text',
      description: useRenderParagraphs(
        t('realSTTDescription', { returnObjects: true }) as TranslationType,
      ),
      imageSrc: realSTT,
      url: '/products/real-stt',
    },
    {
      key: 'real-sid',
      title: t('Real-SID'),
      subtitle: 'Speaker Identification',
      description: useRenderParagraphs(
        t('realSIDDescription', { returnObjects: true }) as TranslationType,
      ),
      imageSrc: realSID,
      url: '/products/real-sid',
    },
    {
      key: 'real-iot',
      title: 'Real-IoT',
      subtitle: 'Internet Of Things',
      description: useRenderParagraphs(
        t('realIOTDescription', { returnObjects: true }) as TranslationType,
      ),
      imageSrc: realIOT,
      url: '/products/real-iot',
    },
    {
      key: 'real-tts',
      title: t('Real-TTS'),
      subtitle: 'Text-To-Speech',
      description: useRenderParagraphs(
        t('realTTSDescription', { returnObjects: true }) as TranslationType,
      ),
      imageSrc: realTTS,
      url: '/products/real-tts',
    },
  ];

  return (
    <ProductContentWrapper>
      <Title>
        <Text type={titleType} weight='bold'>
          {t('productInfo')}
        </Text>
      </Title>
      <BoxContainer>
        {products.map((box, index) => (
          <Box key={index} reverse={index % 2 !== 0}>
            <Image src={box.imageSrc} alt={box.title} reverse={index % 2 !== 0} />
            <TextContainer>
              <Text type={areaTitle} weight='bold' textColor='blue'>
                {box.title}
              </Text>
              <Text type={areaSubtitle} weight='bold' textColor='grey'>
                {box.subtitle}
              </Text>
              <Text type={areaText} weight='bold'>
                <Description>{box.description}</Description>
              </Text>
              <Button
                buttonType={'white-blue'}
                onClickAction={() => navigate(box.url)}
                text={t('learnMore')}
                textColor='blue'
                textType={buttonType}
                size={size}
                weight='bold'
              />
            </TextContainer>
          </Box>
        ))}
      </BoxContainer>
    </ProductContentWrapper>
  );
};

const ProductContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e2e4;
  align-items: center;
  padding-top: 112px;
  padding-bottom: 40px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
  }
`;

const Title = styled.div`
  max-width: 1348px;
  margin: auto;
  padding-bottom: 32px;

  @media only screen and ${deviceBreakpoints.mobile} {
    max-width: 330px;
    padding-left: 2px;
    padding-right: 2px;
  }
`;

const BoxContainer = styled.div`
  flex: 1 1 calc(33.333% - 10px);
  justify-content: center;
  align-items: center;
  width: 1348px;
  margin: auto;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 330px;
  }
`;

const Box = styled.div<{ reverse: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  border-radius: 8px;
  box-sizing: border-box;
  padding-bottom: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    align-items: start;
    padding-bottom: 0px;
    margin-bottom: 60px; /* Added margin to maintain spacing */
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Image = styled.img<{ reverse: boolean }>`
  width: 520px;
  height: 280px;
  border-radius: 10px;
  object-fit: cover;
  border-radius: 8px;
  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    height: 180.923px;
    margin: unset;
    margin-bottom: 20px;
  }
  margin: ${({ reverse }) => (reverse ? '0 0 0 60px' : '0 80px 0 0')};
`;

const TextContainer = styled.div`
  flex: 1;
  @media only screen and ${deviceBreakpoints.mobile} {
    padding-left: 2px;
  }
`;

const Description = styled.div`
  margin: 10px 0;
  padding-bottom: 10px;
`;

export default ProductsSection;
