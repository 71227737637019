import { useEffect, useState } from 'react';

const isSSR = typeof window === 'undefined';

function getWindowDimensions() {
  if (!isSSR) {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
      isMobile: width < 1024,
    };
  }
  return { width: 0, height: 0, isMobile: false };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return { ...windowDimensions };
}
