import Text from 'components/atoms/Text/Text';
import useRenderParagraphs from 'hooks/useRenderParagraphs';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { TranslationType } from 'utils/utils';

import Skyscraper from '../../../assets/aboutUs/skyscraper.jpg';
import Values from '../../../assets/aboutUs/values.jpg';
import Divider from '../../../assets/divider.png';

const StorySection = () => {
  const { t } = useTranslation(['aboutUs']);

  const ourStoryDescription = useRenderParagraphs(
    t('ourStoryDescription', { returnObjects: true }) as TranslationType,
  );
  const ourValuesDescription = useRenderParagraphs(
    t('ourValuesDescription', { returnObjects: true }) as TranslationType,
  );

  const { isMobile } = useWindowDimensions();

  const title = isMobile ? 'title2' : 'display1';
  const boxTitle = isMobile ? 'title1' : 'display2';
  const boxSubtitle = isMobile ? 'title3' : 'title3';
  const boxText = isMobile ? 'label1reading' : 'body1reading';

  return (
    <Container>
      <Text type={title} weight='bold' padding='10px 0px 42px 0px'>
        {t('aboutUs')}
      </Text>
      <BoxContainer>
        <Box reverse={0}>
          <Image src={Skyscraper} alt={'Skyscraper'} />
          <TextContainer reverse={0}>
            <Text type={boxTitle} textColor='gradient' weight='bold' padding='0 0 8px 0'>
              {t('ourStory')}
            </Text>
            <Text type={boxSubtitle} weight='bold' padding='0 0 28px 0'>
              {t('ourStoryTitle')}
            </Text>
            <img
              src={Divider}
              width={'160px'}
              height={'var(--Spacing-s, 8px);'}
              alt={'values-divider'}
            />
            <Text type={boxText} padding='28px 0 0 0'>
              {ourStoryDescription}
            </Text>
          </TextContainer>
        </Box>
        <Box reverse={1}>
          <Image src={Values} alt={'Values'} />
          <TextContainer reverse={1}>
            <Text type={boxTitle} textColor='gradient' weight='bold' padding='0 0 8px 0'>
              {t('ourValues')}
            </Text>
            <Text type={boxSubtitle} weight='bold' padding='0 100px 28px 0'>
              {t('ourValuesTitle')}
            </Text>
            <img
              src={Divider}
              width={'160px'}
              height={'var(--Spacing-s, 8px);'}
              alt={'values-divider'}
            />
            <Text type={boxText} padding='28px 0 0 0'>
              {ourValuesDescription}
            </Text>
          </TextContainer>
        </Box>
      </BoxContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--DW-Semantic-Fill-Normal, #f7f8f9);
  align-items: center;
  padding-top: 112px;
  padding-bottom: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 1440px;
  margin: auto;
  gap: 40px;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    align-items: center;
    gap: 32px;
  }
`;

const Box = styled.div<{ reverse: number }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  margin: 50px 0;
  width: 100%;
  flex: 1;
  gap: 40px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    width: 336px;
    margin: 0;
    gap: unset;
  }
`;

const Image = styled.img`
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 16px;

  @media only screen and ${deviceBreakpoints.mobile} {
    height: 336px;
    width: 336px;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const TextContainer = styled.div<{ reverse: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ reverse }) => (reverse ? '0 80px 0 0' : '0 0 0 80px')};
  gap: 24px;
  flex: 1;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 0px;
    gap: unset;
    padding: 20px 0 40px 0;
  }
`;

export default StorySection;
