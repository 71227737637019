import useRenderParagraphs from 'hooks/useRenderParagraphs';
import useWindowDimensions from 'hooks/useWindowDimensions';
import CollaborativeIcon from 'icons/Collaborative';
import DependableIcon from 'icons/Dependable';
import DrivenIcon from 'icons/Driven';
import GoalIcon from 'icons/Goal';
import InnovativeIcon from 'icons/Innovative';
import SustainableIcon from 'icons/Sustainable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TranslationType } from 'utils/utils';

import Text from '../../../components/atoms/Text/Text';
import { deviceBreakpoints } from '../../../Theme';

const ValuesSection = () => {
  const { t } = useTranslation(['aboutUs']);

  const { isMobile } = useWindowDimensions();

  const title = isMobile ? 'title2' : 'display2';
  const boxTitle = isMobile ? 'heading1' : 'title3';
  const boxText = isMobile ? 'label1reading' : 'body1reading';

  const areas = [
    {
      icon: <DrivenIcon />,
      title: t('ourCoreValueTitle1'),
      description: useRenderParagraphs(
        t('ourCoreValueDescription1', { returnObjects: true }) as TranslationType,
      ),
      position: 'top-left',
    },
    {
      icon: <GoalIcon />,
      title: t('ourCoreValueTitle2'),
      description: useRenderParagraphs(
        t('ourCoreValueDescription2', { returnObjects: true }) as TranslationType,
      ),
      position: 'top-middle',
    },
    {
      icon: <CollaborativeIcon />,
      title: t('ourCoreValueTitle3'),
      description: useRenderParagraphs(
        t('ourCoreValueDescription3', { returnObjects: true }) as TranslationType,
      ),
      position: 'top-right',
    },
  ];

  const bottomAreas = [
    {
      icon: <DependableIcon />,
      title: t('ourCoreValueTitle4'),
      description: useRenderParagraphs(
        t('ourCoreValueDescription4', { returnObjects: true }) as TranslationType,
      ),
      position: 'bottom-left',
    },
    {
      icon: <InnovativeIcon />,
      title: t('ourCoreValueTitle5'),
      description: useRenderParagraphs(
        t('ourCoreValueDescription5', { returnObjects: true }) as TranslationType,
      ),
      position: 'bottom-middle',
    },
    {
      icon: <SustainableIcon />,
      title: t('ourCoreValueTitle6'),
      description: useRenderParagraphs(
        t('ourCoreValueDescription6', { returnObjects: true }) as TranslationType,
      ),
      position: 'bottom-right',
    },
  ];

  return (
    <Container>
      <Text type={title} weight='bold' padding='0 20px'>
        {t('ourCoreValues')}
      </Text>
      <BoxContent>
        <AreaWrapper>
          {areas.map((area, index) => (
            <Area key={index} position={area.position}>
              <Icon>{area.icon}</Icon>
              <Text type={boxTitle} weight='bold'>
                {area.title}
              </Text>
              <Text type={boxText} textColor='lightBlack'>
                {area.description}
              </Text>
            </Area>
          ))}
        </AreaWrapper>
        <AreaWrapper>
          {bottomAreas.map((area, index) => (
            <Area key={index} position={area.position}>
              <Icon>{area.icon}</Icon>
              <Text type={boxTitle} weight='bold'>
                {area.title}
              </Text>
              <Text type={boxText} textColor='lightBlack'>
                {area.description}
              </Text>
            </Area>
          ))}
        </AreaWrapper>
      </BoxContent>
    </Container>
  );
};

const Container = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 112px;
  padding-top: 112px;
  gap: 80px;
  text-align: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-bottom: 40px;
    padding-top: 40px;
    gap: 32px;
  }
`;

const BoxContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1440px;
  margin: auto;
  gap: 40px;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    flex-direction: column;
    gap: 40px;
  }
`;

const AreaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 60px;
  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    flex-direction: column;
    gap: 40px;
  }
`;

const Area = styled.div<{ position: string }>`
  flex: 1 1 calc(33.333% - 60px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 60px;
  gap: 16px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex: 1 1 auto;
    padding: 0;
  }
`;

const Icon = styled.div`
  padding-bottom: 10px;
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
`;

export default ValuesSection;
